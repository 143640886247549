/*
Прикладное программирование в браузере

+ валидация
+ слайдер без анимации
• свой select
+ todo-лист
• корзина товаров
• калькулятор корзины
• фильтры контента
• слайдер c анимацией
• проект с подсчётом часов в неделю
• выбор с подсветкой по очереди
• арифметический тренажёр
• помощник тренера (как повесить вес + программа тренировок с планом + таймер со звуком + авторизация)
* rangebar
*/

export default [

]
